/* -------------------------------------------------------------------------

	PUNITED.useful

	汎用的な関数を定義
	※jQuery必須
	2013/08 Potential United inc.

------------------------------------------------------------------------- */

// グローバル名前空間定義
var PUNITED;
if (!PUNITED) PUNITED = {};
if (!PUNITED.useful) PUNITED.useful = {};

(function (){
	// グローバル名前空間オブジェクトを利用しやすいようにローカルオブジェクトに置き換え
	var ns = PUNITED.useful;


	/* -----------------------------------------------------
	ページトップに戻る
	（利用方法）
	<a href="#" onclick="PUNITED.useful.jumpToPageTop(event);return false;">
	----------------------------------------------------- */
	ns.jumpToPageTop = function() {
		$('html,body').stop().animate({ scrollTop: 0 }, 'normal');
	}


	/* -----------------------------------------------------
	ポップアップウィンドウを開く
	（利用方法）
	<a href="xxx.html" target="_blank" onClick="PUNITED.useful.windowOpen(this.href,'popup',600,500); return false">
	----------------------------------------------------- */
	ns.windowOpen = function(url,winName,width,height) {
		var win = window.open(url,winName,'scrollbars=yes, resizable=yes, menubar=yes, width='+width+',height='+height);
		win.focus();
	}


	/* -----------------------------------------------------
	フルサイズのポップアップウィンドウを開く
	（利用方法）
	<a href="xxx.html" target="_blank" onClick="PUNITED.useful.maxWindowOpen(this.href,'max_popup'); return false">
	----------------------------------------------------- */
	ns.maxWindowOpen = function(url,winName) {
		var win = window.open(url,winName,'toolbar=no, menubar=no, location=no, status=no, scrollbars=yes, resizable=yes');
		win.moveTo(0,0);
		win.resizeTo(screen.availWidth,screen.availHeight);
		win.focus();
	}


	/* -----------------------------------------------------
	タッチデバイスの判別
	（利用方法）
	if(PUNITED.useful.isTouchDevice()) {$("html").addClass('is_touch_device');}
	else{$("html").addClass('is_mouse_device');}
	----------------------------------------------------- */
	ns.isTouchDevice = function() {
		return (typeof window.ontouchstart) !== 'undefined';
	}


	/* -----------------------------------------------------
	hrefを置換する
	（利用方法）
	replaceHref('/', 'http://www.example.com/');
	----------------------------------------------------- */
	ns.replaceHref = function (before, after){

		var $objectSet = $("body a");
		$objectSet.each(function(){
			var hrefString = $(this).attr("href");
			if(hrefString[0]==before){
				hrefString = after + hrefString.slice(1);
				$(this).attr("href",hrefString);
			}
		})
	}


	/* -----------------------------------------------------
	ページ内リンク
	（利用方法）
	PUNITED.useful.innerPageLink(".nav > a", 40);
	//第1引数：リンクのセレクター　第2引数：補正値　第3引数：trueにするとURLにハッシュが付く
	----------------------------------------------------- */
	ns.innerPageLink = function(anc, offset, useHash) {
		var $a = $(anc).filter("[href^='#']");
		$a.on("click",function(e){
			var id = $(this).attr("href");
			var $target;
			if(id=="#"){
				$target = $("html");
			}else{
				$target = $(id);
			}
			if($target.length > 0){
				var top = $target.offset().top - offset;
				$("html,body").stop().animate({scrollTop:top});
			}
			if(!useHash) return false;
		});
	}


	/* -----------------------------------------------------
	ページ内リンク（onClickで書く場合）
	（利用方法）
	<a href="#id" onClick="PUNITED.useful.innerPageLinkTo('#id', 40); return false;">
	第1引数：ターゲットID（ページ最上部へ戻るときは#にする）
	第2引数：補正値
	最後に「return false;」を付けなければURLにハッシュが付く

	----------------------------------------------------- */
	ns.innerPageLinkTo = function(id, offset) {
		var $target;
		if(id=="#"){
			$target = $("html");
		}else{
			$target = $(id);
		}
		if($target.length > 0){
			var top = $target.offset().top - offset;
			$("html,body").stop().animate({scrollTop:top});
		}
	}


	/* -----------------------------------------------------
	外部からのページ内リンク
	（利用方法）
	PUNITED.useful.externalInnerPageLink("anc", 40);
	//第1引数：使用するパラメータ名（ハッシュを使う場合は空にする）　第2引数：補正値

	URLにパラメータ名=ターゲットとなるidをつけてアクセス
	例）/index.html?anc=box03 or /index.html#box03
	----------------------------------------------------- */
	ns.externalInnerPageLink = function(param, offset) {
		var param = PUNITED.useful.getParam(param);
		var $target;
		if(param){
			$target = $("#"+param);
			scrl();

		}else if(location.hash){
			$target = $(location.hash);
			scrl();
		}
		function scrl(){
			if($target.length){
				if(!offset) offset = 0;
				var top = $target.offset().top - offset;
				$("html,body").animate({scrollTop:top});
			}
		}
	}

	/* -----------------------------------------------------
	URLからパラメータ取得
	（利用方法）
	PUNITED.useful.getParam("param");
	//第1引数：取得するパラメータ名
	----------------------------------------------------- */
	ns.getParam = function(key) {
		var str=location.search.substring(1);
		if(str){
			var s = str.split("&");
			for(var i = 0; i<s.length; i++){
				var ss = s[i].split("=");
				if(ss[0] == key)return ss[1];
			}
			return "";
		}else{
			return "";
		}
	}


	/* -----------------------------------------------------
	ブレークポイントチェック
	（利用方法）
		var $obj = PUNITED.useful.checkBreakPont([ブレークポイント1,ブレークポイント2]);
		$obj.on("onPointChanged onPointInit", function(event, point){
			switch( point ){
				case 1:
					ブレークポイント1以下の場合の処理
					break;
				case 2:
					ブレークポイント2以下の場合の処理
					break;
				default:
					それ以外の処理
					break;
			}
		});
	----------------------------------------------------- */
	ns.checkBreakPont = function(points, target_ie8) {

		var
			$obj = $("<div></div>"),
			ie8_flag = (target_ie8)? target_ie8 : false
		;

		var resizeCheckObj = function(){
			var current_point;

			return {
				check: function(){
					var
						resized = false,
						point = points.length + 1,
						w
					;

					if(!ie8_flag){
						w = window.innerWidth;
					}else{
						w = window.innerWidth || $(window).width() + 16;
					}

					for (var i = 0; i < points.length; i++){
						if( w <= points[i] && w ){
							point = i + 1;
							break;
						}
					}
					if( point != this.current_point ) resized = true;
					this.current_point = point;

					return [ point, resized ];
				}
			}
		}

		var resize_check_obj = resizeCheckObj();

		$(window).on("resize", function(){
			var
				check_result = resize_check_obj.check(),
				resize_point = check_result[0],
				resize_flag = check_result[1]
			;
			if(resize_flag){
				$obj.trigger("onPointChanged", resize_point);
			}
		});

		setTimeout(function(){
			$obj.trigger("onPointInit", resize_check_obj.check()[0]);
		},0);

		return $obj;
	}


	/* -----------------------------------------------------
	トグル
	（利用方法）
	PUNITED.useful.simpleToggle(".toggle",".title",".content");
	//第1引数：トリガーと開閉する要素の親要素のセレクター
	//第2引数：トリガーのセレクター（第1引数のセレクターより下を指定）
	//第3引数：開閉する要素のセレクター
	//第4引数：選択状態の要素に付与するクラス （option）
	//第5引数：スピード （option）
	----------------------------------------------------- */
	ns.simpleToggle = function(set, trigger, block, selectedClass, speed) {
		var $sets = $(set);
		if(!selectedClass) selectedClass = "selected";
		if(!speed) speed = "normal";

		$sets.each(function(index, element) {
			var
				$set = $(element),
				$trigger = $set.find(trigger),
				$block = $set.find(block).hide();

			$trigger.on("click", function(event){
				$block.slideToggle(speed);
				$set.toggleClass(selectedClass);
				return false;
			});
		});
	}


	/* -----------------------------------------------------
	アコーディオン
	（利用方法）
PUNITED.useful.simpleAccordion(".accordion",".title",".content");
	//第1引数：トリガーと開閉する要素の親要素のセレクター
	//第2引数：トリガーのセレクター（第1引数のセレクターより下を指定）
	//第3引数：開閉する要素のセレクター
	//第4引数：選択状態の要素に付与するクラス （option）
	//第5引数：スピード （option）
	----------------------------------------------------- */
	ns.simpleAccordion = function(set, trigger, block, selectedClass, speed) {
		var $sets = $(set)
			$blocks = $sets.find(block).hide(),
			$triggers = $sets.find(trigger);
		if(!selectedClass) selectedClass = "selected";
		if(!speed) speed = "normal";

		$triggers.on("click", function(event){
			doit($triggers.index($(this)));
			return false;
		});

		function doit(i){
			$sets.each(function(index, element) {
				var
					$set = $(element),
					$block = $set.find(block);

				if(i == index){
					$block.stop().slideToggle(speed);
					$set.toggleClass(selectedClass);
				}else{
					$block.stop().slideUp(speed);
					$set.removeClass(selectedClass);
				}
			});
		}
	}


	/* -----------------------------------------------------
	タブ
	（利用方法）
PUNITED.useful.simpleTab(".tab",".btns",".contents");
	//第1引数：タブとコンテンツ全体を囲む要素のセレクター
	//第2引数：タブの親要素のセレクター（第1引数のセレクターより下を指定）
	//第3引数：コンテンツの親要素のセレクター
	//第4引数：選択状態の要素に付与するクラス （option）
	----------------------------------------------------- */
	ns.simpleTab = function(set, trigger, block, selectedClass, defTab) {
		var $sets = $(set);
		if(!selectedClass) selectedClass = "selected";
		if(!defTab) defTab = 0;

		$sets.each(function(index, element){
			var $blocks = $(element).find(block).children().hide();
			var $triggers = $(element).find(trigger).children();

			$blocks.eq(defTab).show();
			$triggers.eq(defTab).addClass(selectedClass);

			$triggers.on("click", function(event){
				var num = $triggers.index($(this));
				$blocks.hide().eq(num).show();
				$triggers.removeClass(selectedClass).eq(num).addClass(selectedClass);
				return false;
			});
		});
	}


	/* -----------------------------------------------------
	CSS定義の追加
	（利用方法）
	PUNITED.useful.addCssDefinition(".container","margin","10px");
	----------------------------------------------------- */
	ns.addCssDefinition = function(selector, property, value) {

		var style = document.createElement('style');
		style.setAttribute('type', 'text/css');
		document.getElementsByTagName('head')[0].appendChild(style);
		var sheet = document.styleSheets[document.styleSheets.length - 1];

		if (sheet.insertRule) {
			sheet.insertRule(selector + '{' + property + ':' + value + ';}', sheet.cssRules.length);
		} else {
			sheet.addRule(selector, property + ':' + value);
		}
	}


	/* -----------------------------------------------------
	画像をAlt文字列で置換する
	（利用方法）
	PUNITED.useful.replaceAltText(".alt_replace",959);
	----------------------------------------------------- */
	ns.replaceAltText = function(class,brakepoint) {

		var $elements = $(class);
		var $obj = PUNITED.useful.checkBreakPont([brakepoint]);
		$obj.on("onPointChanged onPointInit", function(event, point){
			switch( point ){
				case 1:
					$elements.each(function(index,elem){
						var alt = $(elem).attr("alt");
						$(elem).hide().after('<span class="alt_replace_text">'+alt+'<span>');
					});
					break;
				default:
					$elements.each(function(index,elem){
						$(elem).show().next(".alt_replace_text").hide();
					});
					break;
			}
		});
	}


	/* -----------------------------------------------------
	ブレークポイントで画像を差し替え
	（利用方法）
	PUNITED.useful.replaceImage("data-spimg",959);
	----------------------------------------------------- */
	ns.replaceImage = function(dataAttr,brakepoint,defaultSp) {

		var $elements = $('['+ dataAttr +']');
		var $obj;
		if(defaultSp) $obj = PUNITED.useful.checkBreakPont([brakepoint-1]);
		else $obj = PUNITED.useful.checkBreakPont([brakepoint]);

		$elements.each(function(index,elem){
			var data = $(elem).data(dataAttr.replace("data-",""));
			var original_src = $(elem).attr("src");
			var replace_src = $(elem).attr("src").replace(/[^/]*$/,data);

			$obj.on("onPointChanged onPointInit", function(event, point){
				switch( point ){
					case 1:
						if(defaultSp) $(elem).attr("src",original_src);
						else $(elem).attr("src",replace_src);
						break;
					default:
						if(defaultSp) $(elem).attr("src",replace_src);
						else $(elem).attr("src",original_src);
						break;
				}
			});
		});
	}

})();
