/* -------------------------------------------------------------------------

	PUNITED.common

	サイト内で共通のスクリプトを定義
	※jQuery必須
	Potential United inc.

------------------------------------------------------------------------- */

// グローバル名前空間定義
var PUNITED;
if (!PUNITED) PUNITED = {};
if (!PUNITED.common) PUNITED.common = {};

(function (){
	// グローバル名前空間オブジェクトをローカルオブジェクトに置き換え
	var ns = PUNITED.common;
	var _isMobile = (/Android|iPhone|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera);

	/* -----------------------------------------------------
		IE10以下の場合にメッセージを表示
	----------------------------------------------------- */
	if ($.browser.is('msie') && $.browser.version.isOrLess(10)) {
		$("html").addClass("regacy_ie");
	}

	/* -----------------------------------------------------

	----------------------------------------------------- */
	ns.toggleNavigation = function() {
		var $navChild = $('.toggle_nav__child');
		var $navParent = $('.toggle_nav__parent');


		function clickEvent() {
			$(this).next().stop().slideToggle();
			$(this).toggleClass('visible');
			return false;
		}

		var $obj = PUNITED.useful.checkBreakPont([679]);
		$obj.on("onPointChanged onPointInit", function(event, point){
			switch( point ){
				case 1:
					$navChild.hide();
					$navParent.on('click', clickEvent);
					break;

				default:
					$navChild.show();
					$navParent.off('click', clickEvent);
					break;
			}
		});
	}
	ns.mobileNavigation = function() {
		var _btnNav = $('.site_header .btn_menu');
		var _body = $('body');
		var _nav = $('.global_nav');

		var _event = '';
		var _status = 'close';
		_event = 'click';

		_btnNav.on(_event, function() {
			if (_status == 'close') {
				_status = 'open';
				_body.addClass('global_nav_visible');
			} else if (_status == 'open') {
				_status = 'close';
				_body.removeClass('global_nav_visible');
			}
		})
	}
	ns.changeViewport = function() {
		var _vp = $('#vp');
		if (!_isMobile) {
			_vp.attr('content', 'width=1400');
		}
	}
	ns.togglePagetop = function() {
		var _wy = 0;
		var _pagetop = $('.page_top');

		window.addEventListener('scroll', function() {
			_wy = document.documentElement.scrollTop || document.body.scrollTop;

			if (_wy > 100) {
				_pagetop.addClass('page_top--show');
				_pagetop.removeClass('page_top--hidden');
			} else {
				_pagetop.addClass('page_top--hidden');
				_pagetop.removeClass('page_top--show');
			}
		})
	}
	ns.toggleRecruit = function() {
		var _wy = 0;
		var _recruit = $('.recruit_banner');

		window.addEventListener('scroll', function() {
			_wy = document.documentElement.scrollTop || document.body.scrollTop;

			if (_wy > 100) {
				_recruit.addClass('recruit_banner--show');
				_recruit.removeClass('recruit_banner--hidden');
			} else {
				_recruit.addClass('recruit_banner--hidden');
				_recruit.removeClass('recruit_banner--show');
			}
		})
	}
	ns.toggleOnline = function() {
		var _wy = 0;
		var _online = $('.online');

		window.addEventListener('scroll', function() {
			_wy = document.documentElement.scrollTop || document.body.scrollTop;

			if (_wy > 100) {
				_online.addClass('online--show');
				_online.removeClass('online--hidden');
			} else {
				_online.addClass('online--hidden');
				_online.removeClass('online--show');
			}
		})
	}
	ns.toggleGraduate = function() {
		var _wy = 0;
		var _graduate = $('.new_graduate_banner');

		window.addEventListener('scroll', function() {
			_wy = document.documentElement.scrollTop || document.body.scrollTop;

			if (_wy > 100) {
				_graduate.addClass('new_graduate_banner--show');
				_graduate.removeClass('new_graduate_banner--hidden');
			} else {
				_graduate.addClass('new_graduate_banner--hidden');
				_graduate.removeClass('new_graduate_banner--show');
			}
		})
	}
	ns.phoneCheck = function() {
		var $phone = $('a[href^="tel:"]');
		if(!_isMobile){
			$phone.addClass('phone__disabled').on('click', function(e){
				e.preventDefault();
			}).attr('href', '#');
		}
	}
	ns.scrollAnimation = function(elem) {
		var self = this;
		var _wy = 0;
		var _wh = window.innerHeight;

		var _mag = 1.1;
		var _delay = 0;

		if (_isMobile) {
			_mag = 1.1;
		}

		window.addEventListener('scroll', function() {
			_wy = document.documentElement.scrollTop || document.body.scrollTop;
		})
		window.addEventListener('resize', function() {
			_wh = window.innerHeight;
		})
		_wy = document.documentElement.scrollTop || document.body.scrollTop;

		var node = Array.prototype.slice.call(elem, 0);

		node.forEach(function(elem) {
			var _interval = 0;
			elem.targetPosY = $(elem).offset().top;
			window.addEventListener('load', function() {
				elem.targetPosY = $(elem).offset().top;
			});

			window.addEventListener('scroll', scrollEvent);
			window.addEventListener('load', loadEvent);
			// scrollEvent();
			loadEvent();

			function scrollEvent() {
				if (_wy + _wh * _mag > elem.targetPosY) {

					window.removeEventListener('scroll', scrollEvent);
					if (!_isMobile) {
						if (elem.dataset.saDuration != undefined) {
							_interval = elem.dataset.saDuration;
						}
					}

					setTimeout(function() {
						elem.classList.add("sa--init");
					}, _interval * 1000)

				}
			}
			function loadEvent() {
				if (_wy + _wh > elem.targetPosY) {

					window.removeEventListener('scroll', scrollEvent);
					if (!_isMobile) {
						if (elem.dataset.saDuration != undefined) {
							_interval = elem.dataset.saDuration;
						}
					}

					setTimeout(function() {
						elem.classList.add("sa--init");
					}, _interval * 1000)

				}
			}
		});
	}
	ns.contactForm = function(){
		var $contactPage = $('body.contact');
		$contactPage.find('form').attr('autocomplete', 'on');
		
		$contactPage.find('.submitForm').on('click', function() {
			$(this).css('pointer-events','none');

			
		});
	}
	ns.noJs = function(){
		window.addEventListener('DOMContentLoaded', function(){
			$('html').removeClass('no-js');
		});
		
	}
})();


$(function(){
	PUNITED.useful.replaceImage("data-spimg",679);
	PUNITED.common.mobileNavigation();
	PUNITED.common.changeViewport();
	PUNITED.common.toggleNavigation();
	PUNITED.common.togglePagetop();
	PUNITED.common.toggleOnline();
	PUNITED.common.toggleRecruit();
	PUNITED.common.toggleGraduate();
	PUNITED.common.phoneCheck();
	PUNITED.common.contactForm();
	PUNITED.common.noJs();
	var _sa = document.querySelectorAll('.sa');

	$("body").animsition({
    inClass: 'overlay-slide-in-right',
    outClass: 'overlay-slide-out-left',
    inDuration: 800,
    outDuration: 650,
    linkElement: 'a:not([target="_blank"]):not([href^="#"]):not([href^="tel:086-948-3981"])',
    // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
    loading: true,
    loadingParentElement: 'body', //animsition wrapper element
    loadingClass: 'animsition-loading',
    loadingInner: '', // e.g '<img src="loading.svg" />'
    timeout: false,
    timeoutCountdown: 5000,
    onLoadEvent: true,
    browser: [ 'animation-duration', '-webkit-animation-duration'],
    // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
    // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
    overlay : true,
    overlayClass : 'animsition-overlay-slide',
    overlayParentElement : 'body',
    transition: function(url){ window.location.href = url; }
  }).on('animsition.inStart', function(){

		var _url = window.location.href;
		var _anchor = '';
		if (_url.match('#')) {
			_anchor = _url.split('#');
		}
		if (_anchor[1] != '' && _anchor[1] != undefined) {
			// console.log('発動')
		} else {
			// console.log('未発動')
		}

	}).on('animsition.inEnd', function() {
		PUNITED.common.scrollAnimation(_sa);
		$('body').addClass('is-loaded');
	});
});
